
<script>
export default {
    props: {
        dateRangeManual: {},
        dateRangeError: {},
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.autoFocusManualInput.focus();
        });
    },
};
</script>
<template>
    <div class="dateRangeManualHeader">
        <div class="row">
            <div class="col-4">
                <div class="p-2 text-left">
                    <div class="pt-2">
                        {{ $t("timepicker.manual_range_selection") }}
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div class="p-2 text-right">
                    {{ $t("timepicker.from") }}
                    <input
                        ref="autoFocusManualInput"
                        v-model="dateRangeManual.from"
                        type="text"
                        size="8"
                        class="dateRangeManualInput"
                        :class="{
                            dateRangeManualInputError: dateRangeError.from,
                        }"
                        :placeholder="$t('timepicker.placeholder_mask_ddmmyy')"
                        @keydown.enter="$emit('exec')"
                    />
                    {{ $t("timepicker.to") }}
                    <input
                        v-model="dateRangeManual.to"
                        type="text"
                        size="8"
                        class="dateRangeManualInput"
                        :class="{
                            dateRangeManualInputError: dateRangeError.to,
                        }"
                        :placeholder="$t('timepicker.placeholder_mask_ddmmyy')"
                        @keydown.enter="$emit('exec')"
                    />
                    <button
                        class="dateRangeManualButton"
                        type="button"
                        @click="$emit('exec')"
                    >
                        {{ $t("timepicker.apply") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<style>
</style>
