/**
 * Setings for the date-range-picker component
 *
 * Doc: https://innologica.github.io/vue2-daterange-picker
 */
import ManualRange from "@/components/Momentum/ManualRange";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc"); // UTC plugin
const timezone = require("dayjs/plugin/timezone"); // timezone plugin
const quarterOfYear = require("dayjs/plugin/quarterOfYear");
const customParseFormat = require("dayjs/plugin/customParseFormat");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);

dayjs.tz.setDefault("Europe/Copenhagen");

export default {
    components: { ManualRange },
    data() {
        return {
            dateRangeManual: {
                from: "",
                to: ""
            },
            dateRangeError: {
                from: false,
                to: false
            }
        };
    },

    methods: {
        manualRangeUpdateDataProperty(newValue, keys) {
            if (keys.length === 0) {
                console.error("No keys provided. Need at least one key.");
                return;
            }

            let target = this.$data;

            // Navigate to the last object/container holding the property
            for (let i = 0; i < keys.length - 1; i++) {
                const key = keys[i];
                if (!target[key]) {
                    // If the intention is not to create undefined keys, you might want to handle this case differently
                    this.$set(target, key, {}); // Using Vue.set to ensure reactivity for new properties
                }
                target = target[key];
            }

            // Update the property with the new value
            const finalKey = keys[keys.length - 1];
            this.$set(target, finalKey, newValue); // Using Vue.set to ensure the update is reactive
        },
        execManualRange(...dateRangeVariableName) {
            const fromDate = dayjs(this.dateRangeManual.from, [
                "DDMMYYYY",
                "DDMMYY",
                "DDMM"
            ]);
            const toDate = dayjs(this.dateRangeManual.to, [
                "DDMMYYYY",
                "DDMMYY",
                "DDMM"
            ]);

            if (fromDate.isValid() && toDate.isValid()) {
                this.manualRangeUpdateDataProperty(
                    {
                        startDate: fromDate.format(),
                        endDate: toDate.format()
                    },
                    dateRangeVariableName
                );

                console.log(dateRangeVariableName);

                // this.$data[dateRangeVariableName] = {
                //     startDate: fromDate.format(),
                //     endDate: toDate.format()
                // };
                this.dateRangeManual = { from: "", to: "" };
                this.$nextTick(() => {
                    this.$refs.picker.togglePicker(false);
                });
            } else {
                this.dateRangeError.from = !fromDate.isValid();
                this.dateRangeError.to = !toDate.isValid();
            }
        },
        serializeDateRange(value) {
            if (
                typeof value === "object" &&
                "endDate" in value &&
                "startDate" in value
            ) {
                if (value.startDate && value.endDate) {
                    return {
                        startDate: dayjs(value.startDate).format(),
                        endDate: dayjs(value.endDate).format()
                    };
                }
            } else {
                return {
                    startDate: null,
                    endDate: null
                };
            }
        },
        getDateRangePickerLocale() {
            let formats = {
                "da-DK": "dd.mm.yyyy"
            };

            return {
                direction: "ltr",
                format: formats[this.$i18n.locale],
                separator: " - ",
                applyLabel: this.$t("dateRangePicker.apply"),
                cancelLabel: this.$t("dateRangePicker.cancel"),
                weekLabel: this.$t("dateRangePicker.week_label"),
                customRangeLabel: this.$t("dateRangePicker.custom_range"),
                daysOfWeek: [
                    this.$t("dateRangePicker.Sun"),
                    this.$t("dateRangePicker.Mon"),
                    this.$t("dateRangePicker.Tue"),
                    this.$t("dateRangePicker.Wed"),
                    this.$t("dateRangePicker.Thu"),
                    this.$t("dateRangePicker.Fri"),
                    this.$t("dateRangePicker.Sat")
                ],
                monthNames: [
                    this.$t("dateRangePicker.Jan"),
                    this.$t("dateRangePicker.Feb"),
                    this.$t("dateRangePicker.Mar"),
                    this.$t("dateRangePicker.Apr"),
                    this.$t("dateRangePicker.May"),
                    this.$t("dateRangePicker.Jun"),
                    this.$t("dateRangePicker.Jul"),
                    this.$t("dateRangePicker.Aug"),
                    this.$t("dateRangePicker.Sep"),
                    this.$t("dateRangePicker.Oct"),
                    this.$t("dateRangePicker.Nov"),
                    this.$t("dateRangePicker.Dec")
                ],
                firstDay: 1
            };
        },

        getDateRangePickerRanges() {
            let today = dayjs().toDate();
            let yesterday = dayjs()
                .subtract(1, "days")
                .toDate();

            let last7Start = dayjs()
                .subtract(7, "days")
                .toDate();
            let last7End = dayjs().toDate();

            let last30Start = dayjs()
                .subtract(30, "days")
                .toDate();
            let last30End = dayjs().toDate();

            let thisMonthStart = dayjs()
                .startOf("month")
                .toDate();
            let thisMonthEnd = dayjs()
                .endOf("month")
                .toDate();

            let lastMonthStart = dayjs()
                .subtract(1, "months")
                .startOf("month")
                .toDate();
            let lastMonthEnd = dayjs()
                .subtract(1, "months")
                .endOf("month")
                .toDate();

            let ytdStart = dayjs()
                .startOf("year")
                .toDate();
            let ytdEnd = dayjs().toDate();

            // Calculate the start and end dates for the last quarter using dayjs
            let lastQuarterStart = dayjs()
                .subtract(1, "quarter")
                .startOf("quarter")
                .toDate();
            let lastQuarterEnd = dayjs()
                .subtract(1, "quarter")
                .endOf("quarter")
                .toDate();

            // ... inside your ranges object

            let ranges = {};

            ranges[this.$t("dateRangePicker.range_today")] = [today, today];
            ranges[this.$t("dateRangePicker.range_yesterday")] = [
                yesterday,
                yesterday
            ];
            ranges[this.$t("dateRangePicker.range_last_7_days")] = [
                last7Start,
                last7End
            ];
            ranges[this.$t("dateRangePicker.range_last_30_days")] = [
                last30Start,
                last30End
            ];
            ranges[this.$t("dateRangePicker.range_this_month")] = [
                thisMonthStart,
                thisMonthEnd
            ];
            ranges[this.$t("dateRangePicker.range_last_month")] = [
                lastMonthStart,
                lastMonthEnd
            ];
            ranges[this.$t("dateRangePicker.range_last_quarter")] = [
                lastQuarterStart,
                lastQuarterEnd
            ];

            ranges[this.$t("dateRangePicker.range_year_to_date")] = [
                ytdStart,
                ytdEnd
            ];

            return ranges;
        },

        getDateRange_YTD() {
            let ytdStart = dayjs()
                .startOf("year")
                .toDate();
            let ytdEnd = dayjs().toDate();

            return {
                startDate: ytdStart,
                endDate: ytdEnd
            };
        }
    }
};
